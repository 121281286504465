import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const InternalContent = makeShortcode("InternalContent");
const ExternalContent = makeShortcode("ExternalContent");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Breadcrumbs items={[{
      name: 'Announcements',
      to: '/whats-new/announcements'
    }, {
      name: 'Canvas v8 Announcements'
    }]} mdxType="Breadcrumbs" />
    <h2 {...{
      "id": "canvas-kit-860-release"
    }}>{`Canvas Kit 8.6.0 Release`}</h2>
    <p>{`Canvas Kit 8.6.0 is now available.`}</p>
    <p>{`This Canvas Kit release contains the following updates:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`inset`}</inlineCode>{` has been added to the `}<inlineCode parentName="li">{`PositionStyle`}</inlineCode>{` prop to enable users to set more than one position
prop with the logical property syntax`}</li>
      <li parentName="ul">{`The `}<inlineCode parentName="li">{`getColor`}</inlineCode>{` API has been extended to `}<a parentName="li" {...{
          "href": "/styles/assets/accent-icons#web-examples"
        }}><inlineCode parentName="a">{`AccentIcon`}</inlineCode></a>{` which
will enable it to use Canvas color tokens for color related props`}</li>
    </ul>
    <p>{`This update will not impact developers. To access these changes, please upgrade to Canvas Kit 8.6.0.`}</p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "april-2023-figma-library-release"
      }}>{`April 2023 Figma Library Release`}</h2>
      <p>{`The Canvas Web Figma library has been updated to minimize breaking changes for the upcoming Canvas
v9 release.`}</p>
      <p>{`This update includes:`}</p>
      <ul>
        <li parentName="ul">{`Re-labeling components to remove emojis and typos that may impact library swapping`}</li>
        <li parentName="ul">{`Updating the component description to indicate what Canvas version a component is being pulled
from`}</li>
        <li parentName="ul">{`Restructuring the Delete Button to match the structure of other Canvas buttons and adding a
phantom x-small layer to it to prevent breaking changes when swapping to the new Canvas Web v9
Figma library`}</li>
        <li parentName="ul">{`Adding text props to Status Indicator and Radio to minimize breaking changes when swapping to the
new Canvas Web v9 Figma library`}</li>
        <li parentName="ul">{`Replacing the icon show/hide prop with a boolean prop in the Status Indicator component to reduce
the number of variants needed and improve memory usage`}</li>
        <li parentName="ul">{`Deprecating Status Indicator variants with the old icon show/hide prop. It is still available for
use in the Canvas Web library, but the component name and description now contain deprecation
warnings`}</li>
        <li parentName="ul">{`Replacing the text show/hide prop with a boolean prop in the Radio/Single component to reduce the
number of variants needed and improve memory usage`}</li>
        <li parentName="ul">{`Deprecating Radio/Single variants with the old text show/hide prop. It is still available for use
in the Canvas Web library, but the component name and description now contain deprecation warnings`}</li>
        <li parentName="ul">{`Merging Segmented Control components into a single variant group to standardize the component
structure`}</li>
      </ul>
      <p>{`This update will not will not result in any breaking changes and should help negate the several
breaking changes identified for the Canvas v9 release.`}</p>
      <p>{`To uptake these bug fixes, please accept the updates in your Figma files.`}</p>
      <h2 {...{
        "id": "april-2023-design-asset-release"
      }}>{`April 2023 Design Asset Release`}</h2>
      <p>{`The April 2023 Design Asset Release is now available for use through the Canvas Assets Figma library
for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following assets were released in this update:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.15`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-find-inventory.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`findInventoryIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-adjust-inventory.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`adjustInventoryIcon`}</inlineCode>{`)`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-move-inventory.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`moveInventoryIcon`}</inlineCode>{`)`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-convert-inventory.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`convertInventoryIcon`}</inlineCode>{`)`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-find-transactions.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`findTransactionsIcon`}</inlineCode>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include the new icons listed above. This release
will not impact developers, and designers can upgrade to the latest version of the Canvas Asset
Figma library without any impact to their existing designs.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "march-2023-design-asset-release"
      }}>{`March 2023 Design Asset Release`}</h2>
      <p>{`The March 2023 Design Asset Release is now available for use through the Canvas Assets Figma library
for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following asset was released as part of this release:`}</p>
      <ul>
        <li parentName="ul">{`Accent Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-accent-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.5`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-accent-notification.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`notificationIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/accent-icons#web-examples"
              }}><inlineCode parentName="a">{`AccentIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The following asset was updated as part of this release:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.11`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-check-circle.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`checkCircleIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) has been updated to remove the faint ring
between layers`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include the new `}<inlineCode parentName="p">{`notification`}</inlineCode>{` accent icon. This
release will not impact developers, and designers can upgrade to the latest version of the Canvas
Asset Figma library without any impact to their existing designs.`}</p>
      <p>{`For more information, or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-850-release"
    }}>{`Canvas Kit 8.5.0 Release`}</h2>
    <p>{`Canvas Kit 8.5.0 is now available.`}</p>
    <p>{`This Canvas Kit release contains the following updates:`}</p>
    <ul>
      <li parentName="ul">{`The overall padding for `}<inlineCode parentName="li">{`Modal`}</inlineCode>{` on smaller screen sizes (320px to 767px) has increased from 16px
to 24px in Canvas Kit to align with Mobile`}</li>
      <li parentName="ul">{`The spacing between `}<inlineCode parentName="li">{`Modal.Heading`}</inlineCode>{` and `}<inlineCode parentName="li">{`Modal.Body`}</inlineCode>{` has been reduced from 16px to 8px on smaller
screen sizes (320px to 767px)`}</li>
      <li parentName="ul">{`An enhancement has be applied to `}<inlineCode parentName="li">{`SystemIcon`}</inlineCode>{` that enables the use of token colors`}</li>
      <li parentName="ul">{`Docgen parsing has been added to enhance documentation for APIs, hooks, and more!`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`The responsive Modal variant in the Canvas Web Figma library has also been updated to decrease the padding between the body copy and action buttons from 32px to 16px.`}</p>
      <p>{`This update will not impact existing design or development work. To access these changes, please
upgrade to Canvas Kit `}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases/tag/v8.5.0"
        }}>{`8.5.0`}</a>{` and accept
the changes to the Figma libraries.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`This update will not impact development work. To access these changes, please upgrade to Canvas Kit
`}<a parentName="p" {...{
          "href": "https://github.com/Workday/canvas-kit/releases/tag/v8.5.0"
        }}>{`8.5.0`}</a>{`.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "february-2023-design-asset-release"
      }}>{`February 2023 Design Asset Release`}</h2>
      <p>{`The February 2023 Design Asset Release is now available for use through the Canvas Assets Figma
library for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following assets were updated as part of this release:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.9`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-check-circle.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`checkCircleIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) has been updated to include additional
layers that will allow teams to modify the background, accent, and fill`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Third Party Icons (upgrade `}<inlineCode parentName="li">{`@workday/third-party-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`1.0.5`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-accent-workday-circle.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`workdayCircleIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`) has been updated to use the correct dub and
sunrise element, which will result in minor design changes to the icon`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include the update to the `}<inlineCode parentName="p">{`check-circle`}</inlineCode>{` system
icon. This release will not impact developers, and designers can upgrade to the latest version of
the Canvas Asset Figma library without any impact to their existing designs.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
    </InternalContent>
    <h2 {...{
      "id": "canvas-kit-840-release"
    }}>{`Canvas Kit 8.4.0 Release`}</h2>
    <p>{`The Canvas Kit React 8.4.0 is now available.`}</p>
    <p>{`This release includes the soft deprecation of the following components:`}</p>
    <ul>
      <li parentName="ul">{`Stack`}</li>
      <li parentName="ul">{`VStack`}</li>
      <li parentName="ul">{`HStack`}</li>
    </ul>
    <p>{`Teams using these soft deprecated components should transition to using the newly enhanced Flex
component. A code mod is also available to help with the transition to Flex.`}</p>
    <p>{`To access these changes, please upgrade to Canvas Kit
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/releases/tag/v8.4.0"
      }}>{`8.4.0`}</a></p>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "january-2023-design-asset-release"
      }}>{`January 2023 Design Asset Release`}</h2>
      <p>{`The January asset release is now available and includes:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.8`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-component-edit.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`componentEditIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include this new icon.`}</p>
      <p>{`In addition to this change, previously deprecated assets have also been removed from the Canvas
Assets Figma library.`}</p>
      <p>{`This release will not impact developers, and designers can upgrade to the latest version of the
Canvas Asset Figma library without any impact to their existing designs.`}</p>
      <blockquote>
        <p parentName="blockquote">{`Note: There were two asset releases in January 2023 due to an alignment issue with the new
component-edit icon. Teams that have already upgraded to 3.0.7 should upgrade to 3.0.8 to access
the correctly aligned component-edit icon.`}</p>
      </blockquote>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
      <h2 {...{
        "id": "december-2022-design-asset-release"
      }}>{`December 2022 Design Asset Release`}</h2>
      <p>{`The December 2022 Design Asset Release is now available for use through the Canvas Assets Figma
library for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following asset was released with this update:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.6`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-compare-files.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`compareFilesIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include the new icon.`}</p>
      <p>{`In addition to this new asset, the following bug fixes have also been rolled out:`}</p>
      <ul>
        <li parentName="ul">{`Illustrations (upgrade `}<inlineCode parentName="li">{`@workday/canvas-graphics-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`4.0.6`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul">{`Removed empty space to fix aspect ratio for `}<inlineCode parentName="li">{`wd-illustration-spot-woman-flying-graphic.svg`}</inlineCode>{`
(`}<inlineCode parentName="li">{`wdIllustrationSpotWomanFlyingGraphic`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/empty-states#web-examples"
              }}><inlineCode parentName="a">{`Graphic`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The deprecated assets on the deprecation page of the Canvas Assets Figma library have also been
hidden as part of this release and will no longer be searchable in the Figma assets panel.`}</p>
      <p>{`This release will not impact developers. Designers can upgrade to the latest version of the Canvas
Assets Figma library without any impact to their existing designs. However, it is recommended to
switch out the deprecated and outdated spot graphics in active design files with the new spot
graphics.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
      <h2 {...{
        "id": "december-2022-figma-library-bug-fixes"
      }}>{`December 2022 Figma Library Bug Fixes`}</h2>
      <p>{`A Canvas Web Figma library update will be released on `}<strong parentName="p">{`December 14th, 2022`}</strong>{` with the following bug
fixes:`}</p>
      <ul>
        <li parentName="ul">{`Missing hover states will be added for Expandable Container`}</li>
        <li parentName="ul">{`The navigational chevron icons in Pagination will be updated to Black Pepper 400 to align with
Canvas Kit and the left hint text will be modified to be right aligned to maintain consistent
spacing`}</li>
        <li parentName="ul">{`The text within Breadcrumbs will be updated to be left and right aligned in order to account for
bidi and layout`}</li>
        <li parentName="ul">{`The height for Search Form will be updated from 48px to 40px to align with Canvas Kit`}</li>
        <li parentName="ul">{`The icon in Search Form has been replace with the new icon only Tertiary Button variant to align
with Canvas Kit`}</li>
      </ul>
      <p>{`The updates to Expandable Container, Pagination, and Breadcrumbs will not have an impact on existing
designs. However, teams that have aligned UI elements to be the same height as Search Form may need
to realign the UI elements to Search Form after they have accepted the changes.`}</p>
      <p>{`To uptake these bug fixes, please accept the updates in your Figma files.`}</p>
      <h2 {...{
        "id": "november-2022-design-asset-release"
      }}>{`November 2022 Design Asset Release`}</h2>
      <p>{`The November 2022 Design Asset Release is now available for use through the Canvas Assets Figma
library for designers and npm, Artifactory, or SAS (in all environments) for developers.`}</p>
      <p>{`The following assets were released with this update:`}</p>
      <ul>
        <li parentName="ul">{`System Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-system-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`3.0.5`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-icon-filter-checked.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`filterCheckedIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/system-icons#web-examples-1"
              }}><inlineCode parentName="a">{`SystemIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Applet Icons (upgrade `}<inlineCode parentName="li">{`@workday/canvas-applet-icons-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`2.0.6`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-applet-guidance-workspace.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`guidanceWorkspaceIcon`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/applet-icons#web-examples"
              }}><inlineCode parentName="a">{`AppletIcon`}</inlineCode></a>{`)`}</li>
          </ul>
        </li>
        <li parentName="ul">{`Illustrations (upgrade `}<inlineCode parentName="li">{`@workday/canvas-graphics-web`}</inlineCode>{` to `}<inlineCode parentName="li">{`4.0.5`}</inlineCode>{`)`}
          <ul parentName="li">
            <li parentName="ul"><inlineCode parentName="li">{`wd-graphic-banner-urban-people.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`bannerUrbanPeopleGraphic`}</inlineCode>{` when using
`}<a parentName="li" {...{
                "href": "/styles/assets/empty-states#web-examples"
              }}><inlineCode parentName="a">{`Graphic`}</inlineCode></a>{`)`}</li>
            <li parentName="ul"><inlineCode parentName="li">{`wd-illustration-spot-woman-flying.svg`}</inlineCode>{` (`}<inlineCode parentName="li">{`wdIllustrationSpotWomanFlyingGraphic`}</inlineCode>{`)`}</li>
          </ul>
        </li>
      </ul>
      <p>{`The Canvas Assets Figma library has been updated to include the new icons and illustrations.`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/48bc22fc19c17f49ef7410f07b450127/5a190/nov-da-release-2022.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "44.5945945945946%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a few deprecated spot illustrations and their replacements along with several new banners and spot illustrations",
              "title": "Image of a few deprecated spot illustrations and their replacements along with several new banners and spot illustrations",
              "src": "/static/48bc22fc19c17f49ef7410f07b450127/50383/nov-da-release-2022.png",
              "srcSet": ["/static/48bc22fc19c17f49ef7410f07b450127/1efb2/nov-da-release-2022.png 370w", "/static/48bc22fc19c17f49ef7410f07b450127/50383/nov-da-release-2022.png 740w", "/static/48bc22fc19c17f49ef7410f07b450127/5a190/nov-da-release-2022.png 800w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <p>{`The following changes have also been made in the Canvas Assets Figma library to align with the
Design Asset repo:`}</p>
      <ul>
        <li parentName="ul">{`10 banners have been added (previously missing from the Canvas Assets Figma library)`}</li>
        <li parentName="ul">{`16 new spots illustrations have been added (previously missing from the Canvas Assets Figma
library)`}</li>
        <li parentName="ul">{`30 outdated spot graphics have been moved to the deprecation page (updated spot graphics are now
all available in the Canvas Assets Figma library)`}</li>
      </ul>
      <p>{`The deprecated assets will be hidden with the December 2022 Design Asset release (December 14th)
before being fully removed from the Canvas Assets Figma library for the January 2023 Design Asset
release (January 11th).`}</p>
      <p>{`This release will not impact developers as they are net-new assets with no changes to previously
released assets. Designers can also upgrade to the latest version of the Canvas Assets Figma library
without any impact to their existing designs. However, it is recommended to switch out the
deprecated and outdated spot graphics in active design files with the new spot graphics.`}</p>
      <p>{`For more information or to access these new icons in this update, please reference the
`}<a parentName="p" {...{
          "href": "https://ghe.megaleo.com/design/design-assets"
        }}>{`Design Asset repo`}</a>{`.`}</p>
      <hr></hr>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h2 {...{
        "id": "canvas-kit-v8-release"
      }}>{`Canvas Kit v8 Release`}</h2>
      <p>{`Canvas Kit v8 will be released on `}<strong parentName="p">{`November 2nd, 2022`}</strong>{`. An upgrade guide will be available once v8
goes live to assist product teams with their migration to the newest version of Canvas Kit.`}</p>
      <blockquote>
        <p parentName="blockquote">{`Note: Due to an unintentional release of Canvas Kit v8.0.0 earlier this year, Canvas Kit v8 will
be released as v8.0.1 to maintain semantic versioning. Teams looking to upgrade to v8 should skip
v8.0.0 and `}<strong parentName="p">{`upgrade straight to v8.0.1 as the accidental release of v8.0.0 has already been
deprecated.`}</strong></p>
      </blockquote>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h2 {...{
        "id": "canvas-kit-v8-and-figma-library-release"
      }}>{`Canvas Kit v8 and Figma Library Release`}</h2>
      <p>{`Canvas Kit v8 will be released on `}<strong parentName="p">{`November 2nd, 2022`}</strong>{`, alongside updates to the Canvas Web Figma
library to reflect the v8 changes. An upgrade guide will be available once v8 goes live to assist
product teams with their migration to the newest version of Canvas Kit.`}</p>
      <blockquote>
        <p parentName="blockquote">{`Note: Due to an unintentional release of Canvas Kit v8.0.0 earlier this year, Canvas Kit v8 will
be released as v8.0.1 to maintain semantic versioning. Teams looking to upgrade to v8 should skip
v8.0.0 and `}<strong parentName="p">{`upgrade straight to v8.0.1 as the accidental release of v8.0.0 has already been
deprecated.`}</strong>{` For more information, please view our previous
`}<a parentName="p" {...{
            "href": "https://workday-dev.slack.com/archives/C8X0W5AQH/p1657915824637479"
          }}>{`Slack announcement`}</a>{`.`}</p>
      </blockquote>
      <h3 {...{
        "id": "release-overview"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v8 release. The impact for designers and developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users accept the changes in Figma and/or upgrade to
Canvas Kit v8`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Designer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`React 18 Upgrade`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit will be upgrading to React 18, but will still maintain support for the same React versions as v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Breadcrumbs Collection Enhancements`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The new Collection Systems introduced in v7 will be added to Breadcrumbs, which will also be promoted to the Main package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Primary Button Data Variant Hard Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Data variant for Primary Button will be hard deprecated in the Canvas Web Figma library`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Medium`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Name Alignment`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated component names in the Canvas Web Figma library and Canvas documentation to align all Canvas touchpoints`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth, Label, and Auto Layout Update for Menu`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Menu in the Main package will be updated to use depth 3 instead of depth 1 to align with the Canvas Figma libraries. The Menu in the Canvas Web Figma library will also be updated to fix text wrapping and alignment issues`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Responsive Utility`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new responsive utility will be released that will provide responsive styles for a given container width. This will also allow developers to easily adjust styles based on their current breakpoint range`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Grid Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Grid component will be released in Canvas Kit to help developers create a grid system with Canvas. Documentation for the new Grid will be available on the Canvas Design System site within the Layout page`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Text Components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New Text components will be released in Canvas Kit to help developers quickly switch between different type token`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Tabs Variants, Fix, and Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A touch based Tab variant will be added to the Canvas Web Figma library as well as updated documentation and a fix to add left padding back to Tabs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Touch and Responsive Behavior for Modal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New touch and responsive based behavior will be added to Modal in Canvas Kit, along with updated documentation and variants in the Canvas Web Library`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Soft Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Drawer, Menu (in Preview), and Layout will be soft deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New and Updated Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated documentation for Modal, Popup, and Tooltip. New documentation for FormField`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Export Clean Up`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Leftover default exports will be removed from Canvas Kit components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Internet Explorer 11 Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`IE specific code will be removed from Canvas Kit`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Checkbox and Radio Optimization`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updates will be made to the Checkbox and Radio components in the Canvas Web Figma library to reduce the component complexity`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Removal of Previously Deprecated Components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Components that have already been hard deprecated over the last few Canvas releases will be removed from the Canvas Web Figma library`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Testing Components and Utilities Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Several testing components and utilities will be promoted to `}<inlineCode parentName="td">{`@workday/canvas-kit-react/testing`}</inlineCode>{`, a new testing package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Refactor and Reorganize Style Props`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Style props implementation will be refactored and reorganized as general clean up and improve documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Redesigned Status Indicator Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new redesigned Status Indicator component with Title Casing will be added to the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Redesigned Segmented Control Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new redesigned Segmented Control component will be added to the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Update for Popup Close Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update close buttons for Popup, Modal, and Dialog to default to `}<inlineCode parentName="td">{`type=button`}</inlineCode>{` so they do not unintentionally submit forms`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Action Bar Overflow Button Refactoring`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The hard-coded OverflowButton in Action Bar will be refactored to allow users to modify and pass props into it`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Utility Soft Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`createEventMap`}</inlineCode>{` and `}<inlineCode parentName="td">{`useEventMap`}</inlineCode>{` will be soft deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
        </tbody>
      </table>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "release-overview-1"
      }}>{`Release Overview`}</h3>
      <p>{`The table below contains a high-level overview of the updates that will be rolled out as part of the
v8 release. The impact for developers are defined as follows:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`None`}</strong>{`: inapplicable to the role or no actions are required for users to adopt the change;
Updates will be applied automatically once users upgrade to Canvas Kit v8`}</li>
        <li parentName="ul"><strong parentName="li">{`Low`}</strong>{`: minor changes are required for users to adopt the change`}</li>
        <li parentName="ul"><strong parentName="li">{`Medium`}</strong>{`: a moderate amount of changes are required for users to adopt the change, such as
switching out UI elements`}</li>
        <li parentName="ul"><strong parentName="li">{`High`}</strong>{`: a large amount of changes are required for users to adopt the change, requiring product
teams to make major design or development decisions`}</li>
      </ul>
      <table>
        <thead parentName="table">
          <tr parentName="thead">
            <th parentName="tr" {...{
              "align": null
            }}>{`Change`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Short Description`}</th>
            <th parentName="tr" {...{
              "align": null
            }}>{`Developer Impact`}</th>
            <th parentName="tr" {...{
              "align": null
            }}></th>
          </tr>
        </thead>
        <tbody parentName="table">
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`React 18 Upgrade`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Canvas Kit will be upgrading to React 18, but will still maintain support for the same React versions as v7`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Breadcrumbs Collection Enhancements`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The new Collection Systems introduced in v7 will be added to Breadcrumbs, which will also be promoted to the Main package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Low`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Name Alignment`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated component names in Canvas documentation to align all Canvas touchpoints`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Depth Update for Menu`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Menu in the Main package will be updated to use depth 3 instead of depth 1`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Responsive Utilities`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New responsive utilities will be released that will allow teams to take a container size and easily adjust styles based on their current breakpoint range`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Grid Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new Grid component will be released in Canvas Kit to help developers create a grid system with Canvas. Documentation for the new Grid will be available on the Canvas Design System site within the Layout page`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Text Components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New Text components will be released in Canvas Kit to help developers quickly switch between different type token`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated Tabs Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The Tabs documentation will be updated to provide guidance for touch based tabs`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New Touch and Responsive Behavior for Modal`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`New touch and responsive based behavior will be added to Modal in Canvas Kit, along with updated documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Component Soft Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Drawer, Menu (in Preview), and Layout will be soft deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`New and Updated Documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Updated documentation for Modal, Popup, and Tooltip. New documentation for FormField`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Export Clean Up`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Leftover default exports will be removed from Canvas Kit components`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Internet Explorer 11 Deprecation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`IE specific code will be removed from Canvas Kit`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Testing Components and Utilities Promotion`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Several testing components and utilities will be promoted to `}<inlineCode parentName="td">{`@workday/canvas-kit-react/testing`}</inlineCode>{`, a new testing package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Refactor and Reorganize Style Props`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Style props implementation will be refactored and reorganized as general clean up and improve documentation`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Redesigned Status Indicator Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new redesigned Status Indicator component with Title Casing will be added to the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Redesigned Segmented Control Component`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`A new redesigned Segmented Control component will be added to the Preview package`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Default Update for Popup Close Buttons`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`Update close buttons for Popup, Modal, and Dialog to default to `}<inlineCode parentName="td">{`type=button`}</inlineCode>{` so they do not unintentionally submit forms`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Action Bar Overflow Button Refactoring`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`The hard-coded OverflowButton in Action Bar will be refactored to allow users to modify and pass props into it`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
          <tr parentName="tbody">
            <td parentName="tr" {...{
              "align": null
            }}>{`Utility Soft Deprecations`}</td>
            <td parentName="tr" {...{
              "align": null
            }}><inlineCode parentName="td">{`createEventMap`}</inlineCode>{` and `}<inlineCode parentName="td">{`useEventMap`}</inlineCode>{` will be soft deprecated`}</td>
            <td parentName="tr" {...{
              "align": null
            }}>{`None`}</td>
            <td parentName="tr" {...{
              "align": null
            }}></td>
          </tr>
        </tbody>
      </table>
    </ExternalContent>
    <h3 {...{
      "id": "react-18-upgrade"
    }}>{`React 18 Upgrade`}</h3>
    <p>{`Canvas Kit will be upgraded to React 18 as part of the v8 release. This will not include any other
dependency updates. However, Canvas Kit will continue to support React 16.14, React 17.X, and React
18.X in v8.`}</p>
    <p>{`This change will not impact product teams that are already on v7 and are looking to upgrade to v8 as
v8 will support the same React versions as v7 while still giving teams the ability to upgrade to
React 18 as needed.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`Since this is a Canvas Kit dependency upgrade, this will not impact existing designs created with
the Canvas Figma libraries.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "breadcrumbs-collection-enhancements"
    }}>{`Breadcrumbs Collection Enhancements`}</h3>
    <p>{`The Collection System released in Canvas Kit v7 will be added to the Breadcrumbs component, which
will help optimize Breadcrumbs and resolve some of the issues associated with the current
Breadcrumbs component. The new Collection System will introduce some visual changes into
Breadcrumbs, so to maintain the same visual design, the following updates will also be made to the
Menu in Breadcrumbs:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Max Width`}</strong>{`: No max width → 280px`}</li>
      <li parentName="ul"><strong parentName="li">{`Max Height`}</strong>{`: 200px → 296px`}</li>
    </ul>
    <p>{`These Menu updates are specific to the Menu embedded into Breadcrumbs, not the standalone Menu
component in the Main or Preview package. With this enhancement, Breadcrumbs will also be promoted
to the Main package.`}</p>
    <p>{`A codemod will be available to help most teams transition to the new v8 Breadcrumbs. However, teams
using `}<inlineCode parentName="p">{`Breadcrumbs.List`}</inlineCode>{` or `}<inlineCode parentName="p">{`Breadcrumbs.CollapsibleList`}</inlineCode>{` will not be able to use codemods for this
update. For additional information, please view our
`}<a parentName="p" {...{
        "href": "https://github.com/Workday/canvas-kit/discussions/1765"
      }}>{`GitHub discussion`}</a>{` to assist with the
Breadcrumbs update.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This enhancement will not impact existing designs.`}</p>
      <h3 {...{
        "id": "primary-button-data-variant-hard-deprecation"
      }}>{`Primary Button Data Variant Hard Deprecation`}</h3>
      <p>{`The data variant for Primary Button will be hard deprecated in v8, meaning that it will be removed
from the Canvas Web Figma library.`}</p>
      <p>{`This variant was already deprecated in Canvas Kit as part of the v7 updates so no action will be
required for developers to uptake this change.`}</p>
      <p>{`This hard deprecation will not impact existing designs, but designers should swap out the data
variant for Primary Button in active designs with the icon left Primary Button variant.`}</p>
      <h3 {...{
        "id": "component-name-alignment"
      }}>{`Component Name Alignment`}</h3>
      <p>{`Component names in the Canvas Web Figma library will also be updated to align with their
counterparts in Canvas Kit in order to improve the design to development hand-off. The following
component names will be updated as part of this release:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Check Box`}</strong>{` to `}<strong parentName="li">{`Checkbox`}</strong></li>
        <li parentName="ul"><strong parentName="li">{`Select Drop-down`}</strong>{` to `}<strong parentName="li">{`Select`}</strong></li>
        <li parentName="ul"><strong parentName="li">{`Badge`}</strong>{` to `}<strong parentName="li">{`Count Badge`}</strong></li>
      </ul>
      <p>{`The Loading Animation component documentation, along with all other mentions of Loading Animation on
the Canvas Design System site, has been updated to reference "Loading Dots" instead. Additionally,
the Loading Animation in Canvas Kit has also been renamed to `}<inlineCode parentName="p">{`LoadingDots`}</inlineCode>{`. This is being done to:`}</p>
      <ul>
        <li parentName="ul">{`Align with the Canvas Web Figma library`}</li>
        <li parentName="ul">{`Create a clearer distinction between Loading Dots and loading animation, which can be used to
describe a wide variety of UI elements (ex: Loading Dots, Skeleton) that are used to indicate that
content is being loaded.`}</li>
      </ul>
      <p>{`A codemod will handle this change when developers upgrade to v8. This change will also not impact
existing designs. However, designers should make note of the name changes when searching for these
components in the Figma assets panel.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "component-name-alignment-1"
      }}>{`Component Name Alignment`}</h3>
      <p>{`The Loading Animation component documentation, along with all other mentions of Loading Animation on
the Canvas Design System site, has been updated to reference "Loading Dots" instead. Additionally,
the Loading Animation in Canvas Kit has also been renamed to `}<inlineCode parentName="p">{`LoadingDots`}</inlineCode>{`. This is being done to
create a clearer distinction between Loading Dots and loading animation, which can be used to
describe a wide variety of UI elements (ex: Loading Dots, Skeleton) that are used to indicate that
content is being loaded. A codemod will handle this change when developers upgrade to v8. No
additional action will be required to uptake this change.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "depth-label-and-auto-layout-update-for-menu"
      }}>{`Depth, Label, and Auto Layout Update for Menu`}</h3>
      <p>{`The Menu in Canvas Kit’s Main package will be updated to use Depth 3 instead of Depth 1 in order to
align with the Menu available in the Canvas Web Figma library. Additionally, the Menu component in
the Canvas Web Figma library will contain updates to:`}</p>
      <ul>
        <li parentName="ul">{`Auto layout to fix text wrapping issues`}</li>
        <li parentName="ul">{`Add a container to the menu label to improve text alignment`}</li>
      </ul>
      <p>{`All three updates are bug fixes that will not impact existing designs. Changes will be applied
automatically, and no actions will be required for developers or designers to uptake this change.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "depth-update-for-menu"
      }}>{`Depth Update for Menu`}</h3>
      <p>{`The Menu in Canvas Kit’s Main package will be updated to use Depth 3 instead of Depth 1.`}</p>
      <p>{`Changes will be applied automatically, and no actions will be required for developers to uptake this
change.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "new-responsive-utilities"
    }}>{`New Responsive Utilities`}</h3>
    <p>{`The following Canvas Kit responsive utilities will be released as part of v8:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`useResponsiveContainerStyles`}</strong>{`: A hook that allows developers to create container-based
responsive styles using style objects.`}</li>
    </ul>
    <p>{`This is a net-new utility that developers can opt-in to start using, but no action is required to
uptake this change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will not impact existing designs.`}</p>
      <h3 {...{
        "id": "new-grid-component"
      }}>{`New Grid Component`}</h3>
      <p>{`A new Grid component created using CSS Grids will be released in Canvas Kit to help developers
create grid systems to align their product(s). This Grid component will be released in the Main
package and correlates to the Layout Grid component in the Canvas Web Figma library that was
released in v7.`}</p>
      <p>{`Since this is a net-new component, no action will be required to uptake this change. However, it is
recommended for teams using the current Layout component in Canvas Kit to switch over to this new
Grid component as Layout will be soft deprecated in v8 (more information will be provided in the
sections below).`}</p>
      <p>{`This update will not impact existing designs. Designers can continue to use the Layout Grid
component in the Canvas Web Figma library.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "new-grid-component-1"
      }}>{`New Grid Component`}</h3>
      <p>{`A new Grid component created using CSS Grids will be released in the Main package in Canvas Kit to
help developers create grid systems to align their product(s). Since this is a net-new component, no
action will be required to uptake this change. However, it is recommended for teams using the
current Layout component in Canvas Kit to switch over to this new Grid component as Layout will be
soft deprecated in v8 (more information will be provided in the sections below).`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "new-text-components"
    }}>{`New Text Components`}</h3>
    <p>{`The following Text components will be released in v8 to help developers quickly apply and switch
between different type tokens:`}</p>
    <ul>
      <li parentName="ul">{`Text`}</li>
      <li parentName="ul">{`LabelText`}</li>
      <li parentName="ul">{`Subtext`}</li>
      <li parentName="ul">{`BodyText`}</li>
      <li parentName="ul">{`Heading`}</li>
      <li parentName="ul">{`Title`}</li>
    </ul>
    <p>{`The Text component will also allow developers to set text styles for component groupings. These will
all be net-new components so no action will be required for developers to uptake this change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The new Text components will also not impact existing designs since it is an implementation detail
intended to improve the developer experience and will not be represented as a component in the
Canvas Web Figma library.`}</p>
      <h3 {...{
        "id": "new-tabs-variants-fix-and-documentation"
      }}>{`New Tabs Variants, Fix, and Documentation`}</h3>
      <p>{`The following Tabs variants will be released in the Canvas Web Figma library as part of the v8
release:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Overflow Fade`}</strong>{`: Scrollable touch based Tabs (released in Canvas Kit 7.1.0) with fade`}</li>
        <li parentName="ul"><strong parentName="li">{`Overflow Menu`}</strong>{`: Tabs with an overflow Menu that can be toggled on/off`}</li>
      </ul>
      <p>{`The new touch based Tabs variant (Overflow Fade) will also be accompanied by updated documentation
for Tabs on the Canvas Design System site and a fix to add a 24px padding back to the left side of
all Tabs variants, with the exception of the full-width variant.`}</p>
      <p>{`This update will not impact developers since it is being rolled out in the Canvas Figma libraries
and on the Canvas Design System site. It will also not impact existing designs as touch based Tabs
will be a net-new variant and the Tabs left side padding fix will be applied automatically once
designers accept the changes.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <h3 {...{
        "id": "updated-tabs-documentation"
      }}>{`Updated Tabs Documentation`}</h3>
      <p>{`The Tabs documentation will be updated to provide additional usage guidance for touch based Tabs.`}</p>
      <p>{`This update will not impact developers since it is being rolled out on the Canvas Design System
site.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "new-touch-and-responsive-behavior-for-modal"
    }}>{`New Touch and Responsive Behavior for Modal`}</h3>
    <p>{`The Modal component will be updated to include new touch and responsive behaviors. Users interacting
with Modal on touch will not be able to exit out of the Modal by tapping on the overlay. The
responsive Modal component will be displayed when users are browsing on screen sizes between 320px
and 768px and will have the following visual differences compared to the standard Modal:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Corner Radius`}</strong>{`: 8px → 24px`}</li>
      <li parentName="ul"><strong parentName="li">{`Padding`}</strong>{`: 32px → 24px`}</li>
      <li parentName="ul"><strong parentName="li">{`Line Spacing between `}<inlineCode parentName="strong">{`Modal.Heading`}</inlineCode>{` and `}<inlineCode parentName="strong">{`Modal.Body`}</inlineCode></strong>{`: 24px → 16px`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`To support responsive Modal on the Canvas Web Figma library, the following variants will also be
added:`}</p>
      <ul>
        <li parentName="ul"><strong parentName="li">{`Responsive Fullwidth`}</strong>{`: Modal with side-by-side center aligned buttons that take up the
fullwidth of the Modal, this is the "Fullwidth" variant in Canvas Web`}</li>
        <li parentName="ul"><strong parentName="li">{`Responsive Stacked`}</strong>{`: Modals with stacked buttons that take up the full width of the Modal, this
is the "Stacked" variant in Canvas Web`}</li>
        <li parentName="ul"><strong parentName="li">{`Responsive Center Aligned`}</strong>{`: Modal with side-by-side center aligned buttons that do not take up
the fullwidth of the Modal, this is the "Center" variant in Canvas Web`}</li>
        <li parentName="ul"><strong parentName="li">{`Responsive Right Aligned`}</strong>{`: Modals with side-by-side right-aligned buttons, this is the "Right"
variant in Canvas Web`}</li>
        <li parentName="ul"><strong parentName="li">{`Responsive Left Aligned (Default) `}</strong>{`: Modals with side-by-side left-aligned buttons, this is the
"Default" variant in Canvas Web`}</li>
      </ul>
    </InternalContent>
    <p>{`The Modal documentation on the Canvas Design System site will be updated to reflect the new touch
and responsive behavior. No action will be required for developers to uptake this change. The new
touch and responsive behaviors will be applied automatically as teams upgrade to v8.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update will also not impact existing designs since it is a net-new variant.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "component-soft-deprecations"
    }}>{`Component Soft Deprecations`}</h3>
    <p>{`Drawer, Layout, and Menu (in Preview) will be soft deprecated in v8. Warnings will be added to
notify users about the soft deprecation and subsequent hard deprecation in v9. The components will
also be renamed to indicate that they are being deprecated.`}</p>
    <p>{`These components will still be available for use in Canvas Kit v8, but will be accompanied by the
deprecation warnings mentioned above. Codemods will be available to help developers uptake the
component name changes as part of the soft deprecation, but developers using:`}</p>
    <ul>
      <li parentName="ul">{`Drawer should start switching over to the Side Panel component in Preview, which contains the same
capabilities as Drawer (and more!)`}</li>
      <li parentName="ul">{`Layout should switch over to the new Grid component that will be released in v8`}</li>
      <li parentName="ul">{`Menu in Preview should start switching over to the most updated Menu component in the Main package`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`Drawer is the only soon-to-be soft deprecated component available in the Canvas Web Figma library.
While it will still be available for use with the deprecation warnings in v8, it is recommended for
designers to switch out all uses of Drawers in active designs with the Side Panel component
(currently available in the Canvas Web Figma library).`}</p>
    </InternalContent>
    <h3 {...{
      "id": "new-and-updated-documentation"
    }}>{`New and Updated Documentation`}</h3>
    <p>{`New usage guidance will be released for the Modal and Popup documentation on the Canvas Design
System site. The Tooltip documentation will be updated to include additional guidance for text
wrapping and new documentation will be released for FormField.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`These documentation updates will not impact existing designs and or development.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "default-export-clean-up"
    }}>{`Default Export Clean Up`}</h3>
    <p>{`The remaining default exports leftover in Canvas Kit will be removed as part of general Canvas Kit
maintenance. A codemod will be available to help developers uptake the change and no actions will be
required.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`Since this is a Canvas Kit maintenance update, it will not impact existing designs created with the
Canvas Figma libraries.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "internet-explorer-11-deprecation"
    }}>{`Internet Explorer 11 Deprecation`}</h3>
    <p>{`Canvas will stop supporting IE11 in v8. The IE11 deprecation will include:`}</p>
    <ul>
      <li parentName="ul">{`Removing IE specific code from Canvas Kit`}</li>
      <li parentName="ul">{`Turning off Chromatic for IE`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`No action will be required from designers or developers to uptake this change. However, teams
supporting IE11 should note that certain components and utilities may be built that IE11 cannot
support. This means applications built using Canvas Kit React v8 and beyond may not be loaded or not
work properly for users accessing it through IE11.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`No action will be required from developers to uptake this change. However, teams supporting IE11
should note that certain components and utilities may be built that IE11 cannot support. This means
applications built using Canvas Kit React v8 and beyond may not be loaded or not work properly for
users accessing it through IE11.`}</p>
    </ExternalContent>
    <InternalContent mdxType="InternalContent">
      <h3 {...{
        "id": "checkbox-and-radio-optimization"
      }}>{`Checkbox and Radio Optimization`}</h3>
      <p>{`The following updates will be made to the Checkbox and Radio components in the Canvas Web Figma
library to improve optimization and minimize the risk of future bugs:`}</p>
      <ul>
        <li parentName="ul">{`Hidden Checkbox and Radio components will be added to replace existing instances of component
inceptions`}</li>
        <li parentName="ul">{`Extra shape layers will be reduced and replaced with a frame that contains a border with drop
shadows in both components`}</li>
      </ul>
      <p>{`This optimization work is specific to the Canvas Web Figma library and no action will be required
from developers. The update will also not impact existing designs.`}</p>
      <h3 {...{
        "id": "removal-of-previously-deprecated-components"
      }}>{`Removal of Previously Deprecated Components`}</h3>
      <p>{`Components that have previously been hard deprecated in past Canvas releases will be completely
removed from the Canvas Web Figma library. This includes all the components on the Deprecated page
of the Canvas Web Figma library, with the exception of Drawer which is being soft deprecated in v8.
These previously hard deprecated components have either been rebuilt for optimization and added to
the Canvas Web page of the Canvas Web Figma Library or are no longer being supported by Canvas.`}</p>
      <p>{`This update will not impact existing designs, but teams using those previously hard deprecated
components should switch to using components that are supported by Canvas on the Canvas Web page of
the Canvas Web Figma library. Since this update only removes previously hard deprecated components
in Figma, no action will be required from developers to uptake this change.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "testing-components-and-utilities-promotion"
    }}>{`Testing Components and Utilities Promotion`}</h3>
    <p>{`The following testing components and utilities from the Common and Labs package will be promoted to
a new package, `}<inlineCode parentName="p">{`@workday/canvas-kit-react/testing`}</inlineCode>{`, which will only be used for visual testing
purposes:`}</p>
    <ul>
      <li parentName="ul">{`StaticStates`}</li>
      <li parentName="ul">{`ComponentStatesTable`}</li>
      <li parentName="ul">{`converToStaticStates`}</li>
      <li parentName="ul">{`permutateProps`}</li>
      <li parentName="ul">{`PropCombination`}</li>
      <li parentName="ul">{`Props`}</li>
      <li parentName="ul">{`PropDeclaration`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`A codemod will be available to help developers uptake this change, but no additional actions will be
required. The update is Canvas Kit specific and will not impact existing designs.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`A codemod will be available to help developers uptake this change, but no additional actions will be
required.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "refactor-and-reorganize-style-props"
    }}>{`Refactor and Reorganize Style Props`}</h3>
    <p>{`Style props will be reorganized and the implementation will be refactored, but the functionality
will remain the same. This will improve the style prop documentation and allow Canvas to extend
style props further in the future. The style props `}<inlineCode parentName="p">{`background`}</inlineCode>{` and `}<inlineCode parentName="p">{`backgroundImage`}</inlineCode>{` will be moving
from the `}<inlineCode parentName="p">{`color`}</inlineCode>{` style prop function to a new style prop function, `}<inlineCode parentName="p">{`background`}</inlineCode>{`.`}</p>
    <p>{`These functions are intended to only be used by Canvas Kit internally, and will not impact Canvas
users. However, teams importing the `}<inlineCode parentName="p">{`color`}</inlineCode>{` style prop function to apply these two style props will
need to update their import statements to import the `}<inlineCode parentName="p">{`background`}</inlineCode>{` style function as well.`}</p>
    <h3 {...{
      "id": "redesigned-status-indicator-component"
    }}>{`Redesigned Status Indicator Component`}</h3>
    <p>{`A new Status Indicator component with title casing will be released in Preview, which will enhance
its globalization capabilities and accessibility. The new Status Indicator component will differ
from the current version in the Main package in the following areas:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Text Casing`}</strong>{`: The new Status Indicator in Preview will use title casing (ex: In Progress)
instead of full capitalization (ex: IN PROGRESS) as full capitalization can be interpreted as rude
or offensive in other languages.`}</li>
      <li parentName="ul"><strong parentName="li">{`Typography`}</strong>{`: added 12/14 Font Size Uppercase Bold (a one-off text style) to the Subtext L
(Bold) type token for increased visibility and consistency`}</li>
      <li parentName="ul"><strong parentName="li">{`Icon Size`}</strong>{`: (14px, 14px) to (20px, 20px) for increased visibility`}</li>
      <li parentName="ul"><strong parentName="li">{`Height`}</strong>{`: 16px to 20px to accommodate a larger font and icon`}</li>
      <li parentName="ul"><strong parentName="li">{`Padding`}</strong>{`: 1px to 0px for top and bottom padding`}</li>
    </ul>
    <p>{`This update will release a net-new component in the Preview package. The current Status Indicator
component in the Main package will not be modified as part of this release. Developers can continue
using the Status Indicator in Main and can transition to the new one in Preview as needed.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`The Status Indicator component in the Canvas Web Figma library will not be modified to reflect the
new version in Preview at this time. As a result, this update will not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "redesigned-segmented-control-component"
    }}>{`Redesigned Segmented Control Component`}</h3>
    <p>{`Segmented Control with text capabilities will be released in Preview to support newly identified use
cases for text only variants.`}</p>
    <p>{`Some key aspects of the component to note include:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Usage`}</strong>{`: Best used for switching between different views of the same content. Use Tabs for
switching between content that is related but not the same. Segments are mutually exclusive and
can contain text and/or icons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Shape`}</strong>{`: The component will continue to feature squared corners to visually distinguish these
controls and their purpose from Standard Buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Color`}</strong>{`: Active/selected segment is white, French Vanilla 100 with a gray, Licorice 200 border
against a light gray, Soap 200 container to make these controls less prominent than Standard
Buttons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Tooltips`}</strong>{`: Canvas Tooltips showing text labels will be added for Icon-only variants to improve
accessibility for ambiguous icons.`}</li>
      <li parentName="ul"><strong parentName="li">{`Container`}</strong>{`: A container around the Segmented Control component has been added.`}</li>
    </ul>
    <InternalContent mdxType="InternalContent">
      <p>{`Since it will be released as a net-new component in Preview and no updates will be made to the
current Segmented Control component in Main for v8, this update will not impact design or
development.`}</p>
    </InternalContent>
    <ExternalContent mdxType="ExternalContent">
      <p>{`Since it will be released as a net-new component in Preview and no updates will be made to the
current Segmented Control component in Main for v8, this update will not impact developers.`}</p>
    </ExternalContent>
    <h3 {...{
      "id": "default-update-for-popup-close-buttons"
    }}>{`Default Update for Popup Close Buttons`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`CloseButton`}</inlineCode>{` for Popup, Modal, and Dialog will be updated to use a default `}<inlineCode parentName="p">{`type=button`}</inlineCode>{` to
prevent forms from unintentionally being submitted. This will not impact development, no action is
required to uptake this change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will also not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "action-bar-overflow-button-refactoring"
    }}>{`Action Bar Overflow Button Refactoring`}</h3>
    <p>{`In v7, the Overflow Button was hardcoded and placed directly inside `}<inlineCode parentName="p">{`ActionBar.List`}</inlineCode>{` as part of an
update to the Action Bar component. This prevented users from modifying the Overflow Button or
passing props into it. To fix this issue, `}<inlineCode parentName="p">{`ActionBar.OverflowButton`}</inlineCode>{` will be replaced with an
`}<inlineCode parentName="p">{`overflowButton`}</inlineCode>{` prop.`}</p>
    <p>{`A codemod will handle this change when developers upgrade to v8.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will also not impact existing designs.`}</p>
    </InternalContent>
    <h3 {...{
      "id": "utility-soft-deprecations"
    }}>{`Utility Soft Deprecations`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`createEventMap`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEventMap`}</inlineCode>{` utilities will be soft deprecated in v8. This soft
deprecation adds a strike-through to the utilities in the code editor, but they will still be
available for developers to use. However, teams using these utilities should switch over to the new
`}<inlineCode parentName="p">{`Model`}</inlineCode>{` and `}<inlineCode parentName="p">{`ToModelConfig`}</inlineCode>{` utilities that were introduced in v7 as `}<inlineCode parentName="p">{`createEventMap`}</inlineCode>{` and
`}<inlineCode parentName="p">{`useEventMap`}</inlineCode>{` will be hard deprecated in v9. The new `}<inlineCode parentName="p">{`Model`}</inlineCode>{` and `}<inlineCode parentName="p">{`ToModelConfig`}</inlineCode>{` utilities are much
more verbose, leveraging Typecript 4.1 functionalities. These new utilities will help streamline the
developer experience and reduce bugs associated with the old `}<inlineCode parentName="p">{`createEventMap`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEventMap`}</inlineCode>{`
utilities.`}</p>
    <p>{`Since `}<inlineCode parentName="p">{`createEventMap`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEventMap`}</inlineCode>{` are only being deprecated in v8, no action will be required
from developers to uptake this change.`}</p>
    <InternalContent mdxType="InternalContent">
      <p>{`This update is specific to Canvas Kit and will also not impact existing designs.`}</p>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      